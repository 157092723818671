import './App.css';
import banner from './assets/banner_v3.jpg';
import background from './assets/background.svg';
import messageBG from './assets/messageBG.png';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FormToGoogleSheet from "./components/FormToGoogleSheet";
import React, {useEffect, useState} from "react";
import SVGLoading from "./components/SVGLoading";
import {Box, Container, CssBaseline, Grid} from "@mui/material";
import ScrollDialog from "./components/ScrollDialog";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Button from "@mui/material/Button";

function App() {
    const [done, setDone] = useState(false);
    const [formStatus, setFormStatus] = useState(false);
    const [load, setLoad] = useState(false)

    const [formReturn, setFormReturn] = useState('')

    useEffect(() => {
        setLoad(true);
        setTimeout(() => setLoad(false), 1000)
    }, [])

    useEffect(() => {
        switch (formStatus) {
            case 'submit':
                setLoad(true);
                break;
            case 'done':
                setLoad(false);
                setDone(true)
                break;
            default:
                break;
        }
    }, [formStatus])

    const LinkPrivacyPage = () => {
        window.open("https://www.evaair.com/zh-hk/website-disclaimer/privacy-policy/");
    }

    return (
        <div className="App" style={{backgroundImage: "url(" + background + ")"}}>
            <SVGLoading setLoad={setLoad} load={load}/>
            <img alt="banner" className="banner" src={banner}/>
            {/*<img className="background" src={background}/>*/}
            <Container component="main" maxWidth="lg"
                       sx={{
                           zIndex: 50,
                       }}
            >
                <CssBaseline/>
                {!done && (
                    <Box
                        sx={{
                            marginTop: "3%",
                            marginBottom: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item lg={6} xs={12} sm={12}>
                                <Box
                                    component="div"
                                    className="messageBG"
                                    sx={{
                                        padding: 3,
                                        marginTop: 2,
                                        marginBottom: 2,
                                        height: '97%',
                                        // backgroundColor: "#dee3dd",
                                        backgroundImage: "url(" + messageBG + ")",
                                    }}
                                >
                                    <h2 className="messageHeader">長榮航空賞飛全世界！</h2>
                                    <p className="messageContent">
                                        飛行應該像音樂一樣無疆界，長榮航空聽見你心中想飛的渴望，誠意賞飛與你一起創造自由的旅遊節奏！搭長榮航空前往台灣、日本、泰國、美加、歐洲及澳洲等多個大小城市，與你飛越長空、跨越國度，踏上旅途放眼世界。
                                    </p>
                                    <p className="messageContent">
                                        參加賞飛全世界活動，憑歌寄意講出你最想飛往的長榮航點，有機會贏取機票，立即起程漫遊世界！
                                    </p>
                                    <p className="messageContent">
                                        唱出你的旅行節奏！
                                    </p>
                                    <p className="messageContent">
                                        請以一句歌詞表達你最想飛去的長榮航點，可以是真實歌詞或改歌詞（必須注明原曲歌詞），並加以解釋選擇當地原因，最有創意答案可以獲得雙人免費機票，讓旅遊夢成真。
                                    </p>
                                    <p className="messageContent">
                                        例子：<br />
                                        一切懷緬的 踏過的 東京都尚好（麗英《東京一轉》）<br />
                                        沒有你 帶香檳 與我私奔 何來浪漫巴黎（魏浚笙《失約巴黎》）<br />
                                    </p>
                                    <p className="messageContentSmall">
                                        獎品包括：<br/>
                                        頭獎 - 長榮航空香港至任何航點豪華經濟艙來回機票2張（共1位得獎者）<br/>
                                        二獎 - 長榮航空香港至任何航點經濟艙來回機票1張 （共4位得獎者）<br/>
                                        三獎 - 長榮航空香港至任何亞洲航點經濟艙來回機票1張 （共4位得獎者）<br/>
                                        優異獎 - 長榮航空空服員小熊（共6位得獎者）、長榮航空 B787-9 3D立體飛機拼圖
                                        (共10位得獎者)、長榮航空環保束口背袋（共10位得獎者）和長榮航空 X
                                        癲噹多功能充電傳輸線收納器（共15位得獎者）<br/>
                                    </p>
                                    {/*<p className="messageHeader">*/}
                                    {/*    誰想飛？長榮賞飛！*/}
                                    {/*</p>*/}
                                </Box>
                            </Grid>
                            <Grid item lg={6} xs={12} sm={12}>
                                <FormToGoogleSheet setFormReturn={setFormReturn} setFormStatus={setFormStatus}
                                                   formStatus={formStatus}/>

                                <Container>
                                    <CssBaseline/>
                                    <Box
                                        sx={{
                                            marginTop: 2,
                                            margin: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid container sx={{}}>
                                            <Grid item xs={12} sm={12} sx={{
                                                color: '#00622a',
                                            }}>
                                                截止日期：即日起至2024年8月1日 23 時 59 分止（香港時間）<br />
                                                得獎者將會於2024年9月30日前，收到專人通知。
                                            </Grid>
                                        </Grid>

                                        <ScrollDialog />

                                        <Button size="large" onClick={LinkPrivacyPage} sx={{
                                            color: '#075e26',
                                        }} startIcon={<PrivacyTipIcon />}>
                                            長榮航空隱私保護政策
                                        </Button>
                                    </Box>
                                </Container>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {done && (
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            minHeight: "30vh",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12} sm={12}>
                                <p className="successSubmit">你已成功提交作品，感謝你的參與。得獎者將收到個別通知，請留意電郵。謝謝你的參與。</p>
                            </Grid>
                            <Grid item lg={12} xs={12} sm={12}>
                                <p className="referenceNumber">Reference No.: {formReturn}</p>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Container>
            <footer className="footer">
                <FlightTakeoffIcon
                    size="36"
                    sx={{}}
                />
            </footer>
            {/*<header className="App-header">*/}
            {/*</header>*/}
        </div>
    );
}

export default App;
