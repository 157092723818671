import './style.css';
import {useEffect, useState} from "react";

const SVGLoading = (props) => {
    // useEffect(() => {
    // }, [props.load])

    return (
        props.load &&
        <div className="loaderContainer" id="loader-container">
            <svg className="loadingSVG" height="800px" width="800px" viewBox="0 0 512 512">
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M391.638,56.403C355.266,20.031,307.096,0,256.001,0H256c-51.095,0-99.265,20.031-135.637,56.403 S63.959,140.945,63.959,192.04v127.919c0,25.496,4.986,50.314,14.82,73.767c11.057,26.366,28.26,50.421,49.752,69.564 C163.795,494.701,209.065,512,256.001,512c51.095,0,99.265-20.031,135.637-56.403c36.372-36.372,56.403-84.543,56.403-135.638 V192.04C448.042,140.945,428.011,92.775,391.638,56.403z M100.479,393.265l5.824-3.953 c41.423-27.469,89.941-44.922,139.987-44.922h20.784l-131.19,98.407C121.287,428.501,109.121,411.645,100.479,393.265z M428.042,319.959c0,94.863-77.178,172.041-172.042,172.041c-37.931,0-74.653-12.625-104.68-35.779L303.072,342.39 c3.442-2.583,4.847-7.079,3.485-11.163c-1.36-4.083-5.183-6.837-9.486-6.837h-50.78c-42.493,0-84.859,11.359-122.326,31.222 c-10.707,5.676-21.117,11.875-31.162,18.657c-5.872-17.474-8.842-35.689-8.842-54.31V192.04C83.959,97.177,161.136,20,256.001,20 s172.041,77.177,172.041,172.04V319.959z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M254.602,64.4c19.919,0,39.09,4.388,56.979,13.04c1.403,0.679,2.887,1,4.348,1c3.715,0,7.283-2.079,9.009-5.647 c2.405-4.972,0.324-10.952-4.647-13.356C299.664,49.459,277.562,44.4,254.602,44.4c-5.523,0-10,4.478-10,10 C244.602,59.922,249.079,64.4,254.602,64.4z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M350.812,91.794c-4.018,3.79-4.203,10.118-0.414,14.137c7.919,8.396,14.71,17.775,20.185,27.877 c1.812,3.342,5.25,5.237,8.801,5.237c1.609,0,3.242-0.39,4.756-1.21c4.855-2.632,6.659-8.701,4.027-13.557 c-6.3-11.624-14.111-22.414-23.218-32.07C361.16,88.191,354.831,88.006,350.812,91.794z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M282.651,147.784c0-19.389-15.427-35.243-34.649-35.937c-8.494-12.643-22.912-20.509-38.396-20.509 c-3.619,0-7.183,0.414-10.656,1.236c-6.343-5.112-14.401-7.946-22.544-7.946c-19.829,0-35.96,16.131-35.96,35.959 c0,1.199,0.06,2.395,0.178,3.581c-8.529,7.121-13.599,17.715-13.599,29.09c0,20.899,17.003,37.902,37.902,37.902 c10.092,0,19.693-4.08,26.715-11.04c5.644,2.386,11.726,3.623,17.964,3.623c6.909,0,13.638-1.521,19.786-4.44 c5.269,2.893,11.219,4.44,17.299,4.44C266.519,183.744,282.651,167.612,282.651,147.784z M246.691,163.744 c-3.784,0-7.456-1.353-10.338-3.809c-3.421-2.917-8.366-3.19-12.089-0.671c-4.331,2.931-9.399,4.479-14.657,4.479 c-5.356,0-10.505-1.605-14.892-4.643c-2.29-1.586-5.137-2.143-7.854-1.542c-2.719,0.603-5.063,2.311-6.47,4.715 c-3.207,5.481-9.131,8.887-15.463,8.887c-9.871,0-17.902-8.031-17.902-17.902c0-6.603,3.619-12.65,9.445-15.782 c4.267-2.293,6.276-7.315,4.769-11.92c-0.526-1.606-0.793-3.278-0.793-4.969c0-8.8,7.16-15.959,15.96-15.959 c4.47,0,8.821,1.961,11.815,5.269c2.566,2.833,4.144,6.576,4.144,10.69c0,5.522,4.477,10,10,10c5.523,0,10-4.478,10-10 c0-3.177-0.419-6.256-1.195-9.192c9.505,0.575,18.095,6.343,22.173,15.036c1.904,4.056,6.271,6.352,10.694,5.617 c0.897-0.149,1.79-0.225,2.654-0.225c8.8,0,15.96,7.159,15.96,15.96S255.49,163.744,246.691,163.744z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M350.276,275.543c0,0,0.001,0.001,0.001,0.001c-0.141-0.242-0.245-0.419-0.269-0.46 C350.027,275.118,350.107,275.255,350.276,275.543z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M350.007,275.084C350.001,275.073,350.001,275.073,350.007,275.084L350.007,275.084z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M354.261,197.897c-8.142,0-16.204,2.833-22.545,7.946c-3.473-0.822-7.036-1.236-10.655-1.236 c-15.483,0-29.902,7.866-38.396,20.509c-19.224,0.693-34.649,16.548-34.649,35.937c0,19.828,16.132,35.96,35.96,35.96 c6.08,0,12.03-1.548,17.299-4.44c6.148,2.919,12.878,4.44,19.786,4.44c6.238,0,12.319-1.237,17.963-3.623 c7.022,6.96,16.624,11.04,26.716,11.04c20.899,0,37.902-17.003,37.902-37.902c0-11.375-5.07-21.97-13.6-29.09 c0.118-1.188,0.178-2.382,0.178-3.581C390.22,214.028,374.089,197.897,354.261,197.897z M365.739,284.431 c-6.33-0.001-12.256-3.406-15.462-8.887c0.334,0.571,0.875,1.495,1.077,1.842c-0.137-0.234-0.463-0.791-1.078-1.843 c-1.406-2.404-3.751-4.112-6.47-4.715c-0.716-0.158-1.44-0.236-2.162-0.236c-2.018,0-4.006,0.61-5.692,1.778 c-4.387,3.037-9.536,4.643-14.892,4.643c-5.258,0-10.326-1.549-14.658-4.479c-3.723-2.52-8.668-2.243-12.09,0.671 c-2.882,2.456-6.553,3.809-10.337,3.809c-8.801,0-15.96-7.159-15.96-15.96c0-8.801,7.159-15.96,15.96-15.96 c0.863,0,1.757,0.076,2.655,0.225c4.424,0.732,8.789-1.561,10.693-5.617c4.078-8.694,12.668-14.46,22.172-15.036 c-0.777,2.936-1.195,6.015-1.195,9.192c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10c0-4.108,1.573-7.846,4.132-10.677 c2.996-3.316,7.351-5.282,11.828-5.282c8.8,0,15.959,7.159,15.959,15.959c0,1.691-0.267,3.363-0.793,4.969 c-1.507,4.604,0.502,9.627,4.77,11.921c5.826,3.131,9.445,9.179,9.445,15.781C383.641,276.4,375.61,284.431,365.739,284.431z M351.387,277.443C351.443,277.54,351.451,277.553,351.387,277.443L351.387,277.443z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M271.541,439.83h-56.61c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h56.61c5.522,0,10-4.478,10-10 C281.541,444.308,277.063,439.83,271.541,439.83z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M323.711,437.609h-9.99c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h9.99c5.522,0,10-4.478,10-10 C333.711,442.087,329.233,437.609,323.711,437.609z"></path>
                <path stroke="#000000" fill="transparent" strokeWidth="4" d="M341.471,379.89h-56.61c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h56.61c5.522,0,10-4.478,10-10 C351.47,384.368,346.994,379.89,341.471,379.89z"></path>
            </svg>
        </div>
    )
}

export default SVGLoading;